@font-face {
    font-family: Promo;
    src: url("../fonts/promo/Promo-Regular.otf") format("opentype");
}

@font-face {
    font-family: Promo;
    font-weight: bolder;
    src: url("../fonts/promo/Promo-Bold.otf") format("opentype");
}

@font-face {
    font-family: Promo;
    font-weight: bold;
    src: url("../fonts/promo/Promo-Medium.otf") format("opentype");
}

@font-face {
    font-family: Promo;
    font-weight: lighter;
    src: url("../fonts/promo/Promo-Light.otf") format("opentype");
}

@font-face {
    font-family: Promo;
    font-weight: 500;
    src: url("../fonts/promo/Promo-Medium.otf") format("opentype");
}



.font-promo {
    font-family: 'Promo', serif !important;
}

.font-promo-bold {
    font-family: 'Promo', serif !important;
    font-weight: bold !important;
}

.font-promo-bolder {
    font-family: 'Promo', serif !important;
    font-weight: bolder !important;
}

.font-promo-light {
    font-family: 'Promo', serif !important;
    font-weight: lighter !important;
}


.font-promo-medium {
    font-family: 'Promo', serif !important;
    font-weight: 500 !important;
}


label.form-label {
    font-weight: bold;
}

.form-range-input-container {
    padding: 50px;
}

.form-range-input-values-label {
    padding: 25px;
}

.spreadsheetClipboard::placeholder {
    text-align: center;
    padding: 8% 0;
}

.ck-editor__editable {
    min-height: 200px !important;
}

.table-cell-height-limited {
    max-height: 100px;
    max-width: 450px;
    overflow: scroll;
}

.system-management.question-options-container .option-correct-answer.option-container {
    background-color: #f6ffde;
}

.tips-container {
    position: fixed;
    right: 0;
    bottom: 0;
    margin: 0 20px 50px 0;
}

.toast-header-red {
    background-color: #ff4646 !important;
    color: white;
}

.row-checkbox {
    zoom: 1.3;
    text-align: center;
}

.border-radius-50 {
    border-radius: 50px;
}

.m-t-5 {
    margin-top: .5em;
}

.m-t-1 {
    margin-top: 1em;
}

.m-t-2 {
    margin-top: 2em;
}

.m-l-1 {
    margin-left: 1em;
}

.m-l-2 {
    margin-left: 2em;
}

.m-r-1 {
    margin-right: 1em;
}

.m-r-2 {
    margin-right: 2em;
}

.nav-pills .nav-link {
    color: #0c7737 !important;
}

.nav-pills .nav-link:hover,
.nav-pills .nav-link:active {
    color: #7ab23a !important;
}

.nav-pills .nav-link.active {
    background-color: #0c7737 !important;
    color: #fff !important;
}

.nav-pills .nav-link.active:hover,
.nav-pills .nav-link.active:active {
    background-color: #7ab23a !important;
}



.link-primary,
.link-primary:focus,
.link-primary:visited {
    color: #0c7737 !important;
    text-decoration: none;
}

.link-primary:hover,
.link-primary:active {
    color: #7ab23a !important;
    text-decoration-color: #7ab23a !important;
}

.activity-answer-observations {
    min-height: 15em !important;
}

.activity-options-container {
    padding-bottom: 10em;
}

.activity-navigation {
    background-color: #F8F9FA;
    position: fixed;
    bottom: 0;
    height: 10em;
    padding-top: 1em !important;
}

.activity-select-question {
    width: 200px;
}

#login-page {
    height: 100vh;
    padding-top: 10%;
    background: linear-gradient(to right, transparent 50%, #fff 50%), no-repeat center;
    background-size: cover;
    background-color: #58b328;
}

/* 
#login-page:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.05;
    background-image: url('/src/presentation/assets/img/background.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
} */

.login-left-panel {
    background-image: url('/src/presentation/assets/img/home-picture.png');
    background-repeat: no-repeat;
    background-size: 550px;
    background-position: top;
    min-height: 550px;
    color: #fff;
    text-align: center;
}

.login-left-panel .welcome-text {
    font-size: 25px;
}

#login-logo {
    width: 280px;
    margin-bottom: 25px;
}

#login-form {
    padding: 2em 0;
    margin-left: 90px;
}

#login-form .section-header {
    font-size: 35px;
}

#login-form .custom-input-text {
    min-height: 55px;
    border-color: #0c7737;
    border-width: 2px;
    background-color: #f7f7f7;
}

#login-form .custom-input-text::placeholder {
    color: gray;
    opacity: 0.3;
}

#login-form .custom-input-label {
    font-size: 14px;
}

#login-form .custom-primary-btn {
    min-height: 55px;
    background-color: #0c7737;
    width: 100%;
}

@media only screen and (max-width: 992px) {
    #login-page {
        height: 100vh;
        padding-top: 10%;
        background-size: cover;
        background-color: #fff;
    }

    #login-form {
        padding: 0;
        margin: 50px 0 0 0;

    }

}

#side-menu-header {
    position: relative;
}

#side-menu-header::before {
    content: "";
    background-image: url('/src/presentation/assets/img/side-menu-header-background.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.side-menu-header-avatar {
    max-width: 70px;
    padding: 0.5em;
}

.side-menu-header-user-name,
.side-menu-header-logout {
    color: #f6f6f4;
}


.side-menu-header-user-name {
    margin-top: 10px;
    font-size: 20px;
    min-width: 200px;
}

.side-menu-header-logout {
    font-size: 12px;
    padding: 0;
    text-decoration: none;
}

.side-menu-header-logout:active,
.side-menu-header-logout:hover {
    color: rgba(0, 0, 0, 0.574);
}

.sidebar-link {
    color: #0c7737;
    /* color: #7ab23a; */
}

.sidebar-link:hover,
.sidebar-link:active {
    /* color: #0c7737; */
    color: #7ab23a;
}

.turnstone-listbox-hightlighted-item {
    background-color: #efefef;
}

.turnstone-listbox {
    border: 1px solid #efefef;
    border-top: none;
    background-color: #fff;
    padding: 10px;
}

.turnstone-clear-button {
    display: block;
    width: 2rem;
    right: 0px;
    top: 0px;
    bottom: 0px;
    position: absolute;
    color: #a8a8a8;
    cursor: pointer;
    border: none;
    background: transparent;
    padding: 0;
}

.pagination-container {
    justify-content: right;
}

.paginator-select-page {
    max-width: 100px;
}

.activity-answers-view .form-check-input:disabled {
    opacity: 1;
}

.activity-answers-view .form-check-input:disabled~.form-check-label,
.activity-answers-view .form-check-input[disabled]~.form-check-label {
    opacity: 1;
}

.activity-answer-option-correct {
    background-color: #f6ffde;
}

.activity-answer-option-evaluable {
    background-color: #fff9cc;
}

.activity-answer-option-correct .form-check-label,
.activity-answer-option .form-check-label {
    display: inline;
}

.activity-answer-option-correct .form-check-input:checked~.form-check-label::before {
    float: right;
    margin: 2px;
    content: '';
    background: url('/src/presentation/assets/img/right.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 20px;
    height: 20px;
    display: block;
}

.activity-answer-option .form-check-input:checked~.form-check-label::before {
    float: right;
    margin: 2px;
    content: '';
    background: url('/src/presentation/assets/img/wrong.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 20px;
    height: 20px;
    display: block;
}

.activity-answer-option:has(.form-check-input:checked),
.activity-answer-option:has(.form-check-input[checked]) {
    background-color: #ff46465f;
}

.link-without-decoration {
    text-decoration: none;
}

.student-dashboard-welcome {
    background-image: url('/src/presentation/assets/img/student-dashboard-background.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 340px;
    border: none;
    padding-right: 20px;
}


#logo-bicolor {
    width: 150px;
    margin-bottom: 10px;
}

.student-dashboard-welcome .welcome-headline {
    color: white;
    padding: 20px 0 0 80px;
}

.student-dashboard-welcome .welcome-headline h4 {
    font-size: 36px;
}

.student-dashboard-welcome .welcome-headline p {
    font-size: 20px;
}

@media only screen and (max-width: 800px) {
    .student-dashboard-welcome {
        background-position: right;
    }

    .student-dashboard-welcome .welcome-headline {
        color: white;
        padding: 20px 0 0 40px;
    }

    .student-dashboard-welcome .welcome-headline h4 {
        font-size: 24px;
    }

    .student-dashboard-welcome .welcome-headline p {
        font-size: 14px;
    }

    #logo-bicolor {
        width: 100px;
    }

}

@media only screen and (max-width: 560px) {
    .blank-space {
        display: none;
    }
}


.student-dashboard-welcome-indicator {
    margin-left: 10px;
    height: 120px;
    border-radius: 150px;
}

.progress-circle-container {
    width: 100px;
}

.no-border {
    border: none;
}

.card-indicator-error {
    color: white;
    background-color: #d24040;
}

.card-indicator-info {
    color: white;
    background-color: #2788e9;
}

.card-indicator-warning {
    background-color: #ee782ac8;
}

.card-indicator-success {
    color: white;
    background-color: #50a529;
}

.dashboard-content {
    background: rgb(248, 249, 250);
    background: linear-gradient(0deg, rgb(248, 249, 250) 10%, rgb(255, 255, 255) 80%);
}

.button-with-badge {
    min-width: 80px;
}

.round-button {
    border-radius: 18px;
}